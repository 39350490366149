<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm class="mb-3" v-if="$can('addons_setup_crud')">
        <div>
          <a href="/create-addons" class="btn btn-info text-white" type="button"
            ><i class="fa fa-plus"></i> Create Add On</a
          >
        </div>
      </b-col>
      <!-- Search -->
      <b-col sm="12">
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Add on name & add on code"
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Add on name, add on code"
                @keyup.enter="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :per-page="limit"
      :data-manager="dataManager"
    >
      <template slot="date-slot" slot-scope="prop">
        <span>{{ prop.rowData.created_at || "-" }}</span>
      </template>
      <template slot="code-slot" slot-scope="prop">
        <span>{{ prop.rowData.code }}</span>
      </template>
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }}</span>
      </template>
      <template slot="bundle-slot" slot-scope="prop">
        <span>{{ prop.rowData.is_bundle }}</span>
      </template>
      <template slot="max-bundle-slot" slot-scope="prop">
        <span>{{ prop.rowData.max_bundle }}</span>
      </template>
      <template slot="ordering-slot" slot-scope="prop">
        <span>{{ prop.rowData.position }}</span>
      </template>
      <template slot="data-type-slot" slot-scope="prop">
        <span>{{ prop.rowData.data_type }}</span>
      </template>
      <template slot="transaction-type-slot" slot-scope="prop">
        <span>{{ prop.rowData.transaction_type }}</span>
      </template>
      <template slot="amount-slot" slot-scope="prop">
        <span>{{ prop.rowData.amount }}</span>
      </template>
      <template slot="period-slot" slot-scope="prop">
        <span>{{ prop.rowData.period }}</span>
      </template>
      <template slot="status-slot" slot-scope="prop">
        <span
          :class="
            prop.rowData.status === 'Active' ? 'text-success' : 'text-danger'
          "
          >{{ prop.rowData.status }}</span
        >
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions" v-if="$can('addons_setup_crud')">
          <button
            type="button"
            class="btn btn-info m-1 w-75"
            @click="onAction('detail', prop.rowData)"
          >
            Detail
          </button>
          <button
            type="button"
            class="btn btn-warning m-1"
            @click="onAction('edit', prop.rowData)"
          >
            <i class="fa fa-pencil"></i>
          </button>
          <button
            type="button"
            class="btn btn-danger m-1"
            @click="onAction('delete', prop.rowData)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="currentPage === 1" @click="doMovePage('first')">
            <i
              v-if="currentPage === 1"
              class="fa fa-angle-double-left disabled"
            ></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="currentPage === 1" @click="doMovePage('prev')">
            <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.currentPage }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";

Vue.use(Vuetable);

export default {
  components: {
    Vuetable
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      page: 1,
      currentPage: 1,
      paginationInfo: "",
      limit: 10,
      data: [],
      isLoading: false,
      isLoadingTable: false,
      filterText: "",
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `addons/transaction-history`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sort: "created_at|desc",
      moreParams: {},
      fields: [
        {
          name: "date-slot",
          sortField: "created_at",
          title: "Date Created"
        },
        {
          name: "code-slot",
          sortField: "code",
          title: "Add On Code"
        },
        {
          name: "name-slot",
          sortField: "name",
          title: "Add On Name"
        },
        {
          name: "bundle-slot",
          sortField: "is_bundle",
          title: "Bundle"
        },
        {
          name: "max-bundle-slot",
          sortField: "max_bundle",
          title: "Max Bundle"
        },
        {
          name: "ordering-slot",
          sortField: "position",
          title: "Ordering"
        },
        {
          name: "data-type-slot",
          sortField: "data_type",
          title: "Data Type"
        },
        {
          name: "transaction-type-slot",
          sortField: "transaction_type",
          title: "Transaction Type"
        },
        {
          name: "amount-slot",
          sortField: "amount",
          title: "Amount"
        },
        {
          name: "period-slot",
          sortField: "period",
          title: "Period"
        },
        {
          name: "status-slot",
          sortField: "status",
          title: "status"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ]
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    }
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
    this.fetchs();
  },
  methods: {
    fetchs() {
      const newParams = this.stringifyParams(this.moreParams);
      this.isLoading = true;
      this.$http
        .get(
          `addons/setup/list?sort=${encodeURIComponent(this.sort)}&page=${
            this.page
          }&per_page=10${newParams}`
        )
        .then(response => {
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = startIndex + this.limit;

          this.data = response.data.data;
          this.currentPage = response.data.current_page;
          this.paginationInfo =
            response.data.data.length > 0
              ? `Displaying ${startIndex + 1} to ${endIndex}`
              : "No relevant data";

          if (this.isLoadingTable) this.isLoadingTable = false;
          if (this.isLoading) this.isLoading = false;
        })
        .catch(err => {
          this.handleLoadError(err);
        });
    },
    activeLabel(value) {
      if (value == true) {
        return '<span class="ui green label"><i class="fa fa-check"></i></span>';
      } else {
        return '<span class="ui red label"><i class="fa fa-times"></i></span>';
      }
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    doFilter() {
      this.$events.$emit("filter-set", this.filterText);
    },
    onFilterSet(filterText) {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );
      this.moreParams = {
        ...newParams,
        filter: encodeURIComponent(filterText)
      };
      this.fetchs();
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.$emit("filter-reset");
    },
    onFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );
      this.page = 1;
      this.fetchs();
    },
    stringifyParams(params) {
      const filteredKeys = Object.keys(params).filter(key => {
        const value = params[key];
        return value !== "" && value !== null && value !== undefined;
      });

      if (filteredKeys.length === 0) {
        return "";
      } else if (filteredKeys.length === 1) {
        const key = filteredKeys[0];
        return `&${key}=${params[key]}`;
      } else {
        return `&${filteredKeys.map(key => `${key}=${params[key]}`).join("&")}`;
      }
    },
    doMovePage(value) {
      this.$events.$emit("movePage", value);
    },
    onMovePage(eventData) {
      if (eventData === "first") {
        this.page = 1;
        this.fetchs();
      } else if (eventData === "prev") {
        this.page--;
        this.fetchs();
      } else {
        this.page++;
        this.fetchs();
      }
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.fetchs();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: local.length > 0 ? _.slice(local, from, to) : []
      };
    },
    onAction(action, data) {
      if (action === "detail") {
        const routeData = this.$router.resolve({
          name: "Add Ons Detail",
          params: { id: btoa(data.id) }
        });
        window.open(routeData.href, "_blank");
      } else if (action === "edit") {
        const routeData = this.$router.resolve({
          name: "Add Ons Edit",
          params: { id: btoa(data.id) }
        });
        window.open(routeData.href, "_blank");
      } else if (action === "delete") {
        this.onDelete(data);
      }
    },
    onDelete(data) {
      this.isLoading = true;
      this.$swal
        .fire({
          title: "Are You Sure ?",
          text: `You want to delete the ${data.name}!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Back"
        })
        .then(res => {
          if (res.value) {
            this.$http
              .post(`/addons/setup/delete?id=${data.id}`)
              .then(response => {
                this.isLoading = false;
                this.$swal
                  .fire({
                    title: "Success!",
                    html: `${
                      response.data?.meta?.message === undefined
                        ? ``
                        : response.data?.meta?.message
                    }`,
                    type: "success",
                    showCancelButton: false,
                    confirmButtonText: "Kembali Ke Menu",
                    cancelButtonText: "Tutup"
                  })
                  .then(() => {
                    this.fetchs();
                    this.$refs.vuetable.refresh();
                    this.loadingSubmit = false;
                  });
              })
              .catch(error => {
                if (error.response) {
                  this.isLoading = false;
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status === 404) {
                    this.$swal
                      .fire("Failed!", "Add on failed to updated.", "error")
                      .then(() => {
                        location.reload();
                      });
                  }

                  if (this.errors.status === 400) {
                    this.$swal.fire("Failed!", this.errors.message, "error");
                  }
                  this.loadingSubmit = false;
                }
              });
          } else {
            this.isLoading = false;
          }
        });
    }
  },
  mounted() {
    this.$events.$on("filter-set", eventData => this.onFilterSet(eventData));
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("movePage", eventData => this.onMovePage(eventData));
  }
};
</script>
<style>
.loading-indicator {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.badge--code {
  font-size: 10pt !important;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
